import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TabDescription from '../components/TabDescription'
import SEO from '../components/SEO'

export const query = graphql`
  {
    mytab: contentfulTabDescription(tabname: { eq: "Privacy" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }
  }
`
const privacy = ({ data: { mytab } }) => {
  return (
    <Layout>
      <SEO
        title='Privacy Beleid'
        description='Lees zeker mijn privacy beleid 🙏'
      />
      <main className='page'>
        <section className='privacy-page'>
          <article>
            <TabDescription {...mytab} />
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default privacy
