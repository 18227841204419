import React from 'react';

const TabDescription = (mytab) => {
  return (
    <div className='subheader'>
      <div className='subheader-container'>
        <h1>{mytab.title}</h1>
        <div
          className='subheader-text'
          dangerouslySetInnerHTML={{
            __html:
              mytab.description && mytab.description.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  );
};

export default TabDescription;
